<template>
  <div class="body d-flex flex-row">
    <div class="container pt-3">
      <header>
        <div class="text-center">
          <img class="img-fluid" src="../../../../public/imagens/header_hospital.png" alt="">
        </div>
      </header>
      <div class="d-flex flex-row corpo">
        <!-- Llamado Actual -->
        <div class="col-6">
          <div class="card full-height">
            <div class="card-header">
              <div class="card-title pt-3 pb-3">
                <h5 class="">LLAMADO ACTUAL</h5>
              </div>



            </div>
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <label for="nombre"
                    class="contra col-sm-4 col-form-label col-form-label-lg bd-highlight">NOMBRE:</label>
                  <div class="col-8">
                    <input type="text" v-model="chamado.nome" class="input form-control form-control-lg" id="nombre"
                      placeholder="">
                  </div>
                </div>
                <div class="row texto">
                  <label for="consultorio"
                    class="contra col-sm-4 col-form-label col-form-label-lg text-nowrap bd-highlight">CONSULTORIO:</label>
                  <div class="col-8">
                    <input type="text" v-model="chamado.nome_sala" class="input form-control form-control-lg"
                      id="consultorio" placeholder="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Ultimos -->
        <div class="ml-4">
          <div class="card full-height bg-ligth">
            <div class="card-header">
              <div class="card-title pt-3 pb-3">
                <h5 class="card-title">ÚLTIMOS</h5>
              </div>
            </div>
            <div class="card-body texto">
              <table class="table">
                <thead class="table-primary texto">
                  <th scope="col texto">
                    <p class="texto">NOMBRE</p>
                  </th>
                  <th scope="col texto">
                    <p class="texto">CONSULTORIO</p>
                  </th>
                </thead>
                <tbody>

                  <tr v-for="(item, index) in ultimos" :key="index">
                    <td class="texto">{{ item.nome }}</td>
                    <td class="texto">{{ item.nome_sala }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import io from 'socket.io-client'
import Swal from 'sweetalert2'
export default {

  data() {
    return {
      chamado: { nome: "", senha: "", id: "", nome_sala: "" },
      proximos: [],
      ultimos: [],
      total_ultimos: [],
      selectedVoice: 6,
      synth: window.speechSynthesis,
      voiceList: [],
      greetingSpeech: new window.SpeechSynthesisUtterance()
    }
  },
  mounted() {
    this.voiceList = this.synth.getVoices()
    if (this.voiceList.length) {
      this.isLoading = false
    }
    this.synth.onvoiceschanged = () => {
      this.voiceList = this.synth.getVoices()
      setTimeout(() => {
        this.isLoading = false
      }, 800)
    }
    this.listenForSpeechEvents()
  },
  created() {
    this.observar_dados()
  },
  methods: {
    listenForSpeechEvents() {
      this.greetingSpeech.onstart = () => {
        this.isLoading = true
      }
      this.greetingSpeech.onend = () => {
        this.isLoading = false
      }
    },

    greet(value) {
      this.greetingSpeech.text = 'Paciente ' + value.nome + ', ' + value.nome_sala;
      this.greetingSpeech.voice = this.voiceList[this.selectedVoice]
      this.greetingSpeech.lang = "es-ES";
      this.synth.speak(this.greetingSpeech)
    },

    teste() {
      const socket = io("https://fila.pagwise.com/events");
      let urlParams = new URLSearchParams(window.location.search);
      let tela_id = urlParams.get("tela")
      socket.emit('message', tela_id)
    },

    observar_dados() {
      const socket = io("https://fila.pagwise.com/events");
      console.log("OBSERVANDO")
      let urlParams = new URLSearchParams(window.location.search);
      let tela_id = urlParams.get("tela")

      socket.on("connect", () => {
        let urlParams = new URLSearchParams(window.location.search);
        let tela_id = urlParams.get("tela")
        socket.emit('conectar', tela_id)
        console.log("conectado " + tela_id)
      });

      socket.on(tela_id, (value, tipo) => {
        console.log("OBSERVANDO", value)
        var proximos = []
        var ultimos = []
        for (let i in value) {
          if (value[i].status == 0) {
            proximos.push(value[i])
          }
          if (value[i].status == 1) {
            ultimos.push(value[i])
          }
          console.log(proximos)
        }
        this.proximos = proximos
        if (tipo == "chamar") {

          if (ultimos.length > 0) {

            this.ultimos = ultimos.reverse().slice(0, 6);
            console.log("UTIMO", this.ultimos)

            this.chamado = this.ultimos[0]
            window.nome = this.ultimos[0].nome
            window.nome_sala = this.ultimos[0].nome_sala

            this.greet(this.chamado)

            Swal.fire({
              title: ' <h1 class="texto"><strong>' + window.nome + '</strong></h1>',
              html:
                '<h3><b>' + window.nome_sala + '</b></h3>',
              showCloseButton: false,
              showCancelButton: false,
              focusConfirm: false,
              showConfirmButton: false,
              timer: 6000
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
html,
.body {
  height: 100%;
  font-size: 25px;
  background-color: white;
  justify-content: center;
}

.corpo {
  padding-top: 55px;
}

img {
  /* width: 100%; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h5 {
  font-size: 30px;
  text-align: center;
}

.encabezado {
  /* background: url(img/ucplogo2.png) no-repeat center center; */
  background-size: cover;
  height: 380px;
}

.header {
  align-items: center;

}

.texto {
  font-size: 24px;
}

.contra {
  font-size: 25px;
  /* font-weight: bold; */
  color: #141061;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 4px 4px 8px #d3d4d6;
}

.input {
  font-size: 25px;
  font-weight: 500;
  background-color: rgb(177, 211, 245);
  color: #141061;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 4px 4px 8px #d3d4d6;
}

.titulo {
  font-weight: bold 20px;
  color: #141061;
}
</style>
